import React, { useEffect } from "react";
import { Button, message } from "antd";

import CollapseFeatures from "./CollapseFeatures";
import Teams from "./Teams";
import Pricing from "./Pricing";
import Billing from "../Billing";

import { handleFetchTeams, getFinanceDetail } from "./services";
import {
  isEmpty,
  searchToObject,
  getAccessToken,
  removeAccessToken,
} from "../../utils";
import useStore from "../../store";

import "./Features.scss";

const MakePayment = (props) => {
  const setIsLoggedIn = useStore(({ setIsLoggedIn }) => setIsLoggedIn);
  const [loading, setIsLoading] = React.useState(false);

  const [team, setTeam] = React.useState({});
  const [params, setParams] = React.useState({});
  const [finance, setFinance] = React.useState({});
  const [teams, setTeams] = React.useState([]);
  const [pricing, setPricing] = React.useState({});
  const [pricings, setPricings] = React.useState([
    { pricing: 0, type: "annually" },
    { pricing: 0, type: "monthly" },
  ]);
  const [showBilling, setShowBilling] = React.useState(false);

  const onSelectPricing = ({ target: { value } }) => {
    return setPricing(pricings[value]);
  };

  const onSelectTeam = (value) => {
    setPricings((prevPricings) => {
      return prevPricings.map((item) => {
        if (item.type === "annually") {
          item.pricing = teams[value].ANUALLY;
          setPricing(item);
        } else {
          item.pricing = teams[value].MONTHLY;
        }

        return item;
      });
    });
    return setTeam(teams[value]);
  };

  const handleNavigateToBilling = () => {
    setShowBilling(true);
  };

  const fetchTeam = async () => {
    setIsLoading(true);
    const token = getAccessToken();
    const { result, msg, extra } = await handleFetchTeams(token);
    setIsLoading(false);

    if (result === false) {
      return message.error(msg);
    }

    return setTeams(extra);
  };

  useEffect(() => {
    const query = searchToObject(window.location.search);
    async function checkToken(token) {
      const { extra } = await handleFetchTeams(token);
      if (extra.length > 0) {
        setParams(query);
        setShowBilling(true);
        window.history.replaceState({}, "", window.location.pathname);
        return;
      }
      removeAccessToken();
      setIsLoggedIn(false);
      window.location.href = "/login";
      return;
    }
    async function getFinanceDetailData(token, id) {
      const { extra, result } = await getFinanceDetail(id, token);

      if (result) {
        console.log("result:", result);
        setFinance({
          ...extra,
          TYPE: "finance",
          finance_detail_id: id,
          pricing: query?.amount,
        });
        setShowBilling(true);
        window.history.replaceState({}, "", window.location.pathname);
        return;
      }
      removeAccessToken();
      setIsLoggedIn(false);
      window.location.href = "/login";
      return;
    }

    if (query?.finance_detail_id) {
      getFinanceDetailData(getAccessToken(), query?.finance_detail_id);
      return;
    }
    if (Object.keys(query).length > 0) {
      checkToken(getAccessToken());
    } else {
      fetchTeam();
    }
  }, [setIsLoggedIn]);

  return (
    <Billing
      teamId={finance?.TEAM_NAME || params?.id || team?.ID}
      teamName={finance?.TEAM_NAME || params?.name || team?.NAME}
      pricing={finance?.pricing || params?.pricing || pricing.pricing}
      packageType={finance?.TYPE || params?.type || pricing.type}
      description={finance?.DESCRIPTION}
      financeDetailId={finance?.finance_detail_id}
    />
  );
};

export default MakePayment;
