import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./Header";
import Features from "../Features";
import MakePayment from "../MakePayment";
import Login from "../Login";
import Result from "../Result";

import "./Layout.scss";

import useStore from "../../store";

import { getAccessToken, setAccessToken, searchToObject } from "../../utils";

const Layout = () => {
  const isLoggedIn = useStore(({ isLoggedIn }) => isLoggedIn);
  const setIsLoggedIn = useStore(({ setIsLoggedIn }) => setIsLoggedIn);
  const query = searchToObject(window.location.search);
  if (query?.token) {
    setAccessToken(query?.token);
    setIsLoggedIn(true);
  }

  return (
    <Router>
      <div>
        <Header />

        <div className="main-layout">
          <Switch>
            {isLoggedIn && getAccessToken() ? (
              <>
                <Route path="/features">
                  <Features />
                </Route>
                <Route path="/make-payment">
                  <MakePayment />
                </Route>
                <Route path="/result/success/:name/:pricing">
                  <Result type="success" />
                </Route>

                <Route path="/result/error/:pricing">
                  <Result type="error" />
                </Route>

                {Object.keys(query).length === 0 && window.location.pathname == "/" && <Redirect to="/features" />}
              </>
            ) : (
              <>
                <Route path="/login">
                  <Login />
                </Route>

                <Redirect to="/login" />
              </>
            )}

            <Redirect to="/features" />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default Layout;
