import axios from "axios";
import api from "../../api";
import { getAccessToken } from "../../utils";

export const handleTokenizeSuccess = async ({ response }) => {
  const params = new URLSearchParams();

  params.append("token", getAccessToken());
  params.append("response", response);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("upgrade/add_tokenize", params, config);

  return data;
};

export const handleBillingSuccess = async ({
  team_id,
  packageType,
  charged,
  response,
}) => {
  const params = new URLSearchParams();

  params.append("token", getAccessToken());
  params.append("team_id", team_id);
  params.append("package", packageType);
  params.append("charged", charged);
  params.append("response", response);
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post(
    "upgrade/add_upgrade_success",
    params,
    config
  );

  return data;
};

export const handleBilling = async ({
  finance_detail_id,
  status,
  charged,
  response,
}) => {
  const params = new URLSearchParams();

  params.append("token", getAccessToken());
  params.append("finance_detail_id", finance_detail_id);
  params.append("status", status);
  params.append("charged", charged);
  params.append("response", response);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post(
    "upgrade/add_paid_fee_result",
    params,
    config
  );

  return data;
};

export const handleBillingFail = async ({
  team_id,
  packageType,
  charged,
  response,
}) => {
  const params = new URLSearchParams();

  params.append("token", getAccessToken());
  params.append("team_id", team_id);
  params.append("package", packageType);
  params.append("charged", charged);
  params.append("response", response);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("upgrade/add_upgrade_fail", params, config);

  return data;
};

export const handleCreateScheduledInvoice = async ({
  freq,
  customerId,
  url,
  paymentMethodId,
  total,
}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      Accept: "application/json",
      'Access-Control-Allow-Origin': '*',
    },
  };

  let today = new Date();

  if (freq === "YEARLY") {
    today.setFullYear(today.getFullYear() + 1);
  } else {
    today.setMonth(today.getMonth() + 1);
  }

  let dateStr = today.toISOString();
  let dateArr = dateStr
    .split("")
    .filter((key) => key !== "." && key !== "-" && key !== ":");
  dateArr.splice(9, 9, "000000");
  dateStr = dateArr.join("");

  const { data } = await axios.post(
    "https://apiprod.fattlabs.com/invoice/schedule/",
    JSON.stringify({
      rule: `FREQ=${freq};DTSTART=${dateStr}`,
      customer_id: customerId,
      total: total,
      url: url,
      email_notification: false,
      payment_method_id: paymentMethodId,
    }),
    config
  );

  return data;
};
