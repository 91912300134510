import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import {
  BankOutlined,
  CreditCardOutlined,
  LockFilled,
} from "@ant-design/icons";

import { Button, Input, Form, Row, Col, Radio, message, Select } from "antd";
import CardPayment from "./CardPayment";
import BankPayment from "./BankPayment";
import {
  handleBillingFail,
  handleBillingSuccess,
  handleCreateScheduledInvoice,
  handleTokenizeSuccess,
  handleBilling,
} from "./services";

import { USA_STATES, CAN_STATES } from "../../appConstants";
const BillingForm = ({
  teamId,
  pricing,
  packageType,
  teamName,
  financeDetailId,
}) => {
  const fattJs = useRef(null);
  const [form] = Form.useForm();
  const [isCardPayment, setIsCardPayment] = useState(true);
  const [country, setCountry] = useState("USA");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const resetInputError = () => {
    [
      "firstname",
      "lastname",
      "companyName",
      "email",
      "address_country",
      "phone",
      "address_1",
      "address_2",
      "address_city",
      "address_state",
      "address_zip",
      "expiration",
      "bank_name",
      "bank_type",
      "bank_holder_type",
      "bank_account",
      "bank_routing",
    ].forEach((key) => {
      form.setFields([
        {
          name: key,
          errors: [],
        },
      ]);
    });
  };

  const handlePostError = async (err) => {
    if (financeDetailId) {
      await handleBilling({
        finance_detail_id: financeDetailId,
        status: 0,
        charged: pricing,
        response: err,
      });
    } else {
      await handleBillingFail({
        team_id: teamId,
        packageType: packageType,
        charged: pricing,
        response: err,
      });
    }
  };

  const postMessage = (message) => {
    if (window.AnalyticsWebInterface) {
      // for Android
      window.AnalyticsWebInterface.logEvent("Native", JSON.stringify(message));
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.Native
    ) {
      // for iOS
      window.webkit.messageHandlers.Native.postMessage(message);
    } else {
      console.log("No native APIs found.");
    }
  };

  const onPay = () => {
    setIsLoading(true);
    resetInputError();

    const {
      firstname,
      lastname,
      companyName,
      email,
      address_country,
      phone,
      address_1,
      address_2,
      address_city,
      address_state,
      address_zip,
      expiration,
      bank_name,
      bank_type,
      bank_holder_type,
      bank_account,
      bank_routing,
    } = form.getFieldsValue();

    var extraDetails = {
      total: pricing,
      firstname,
      lastname,
      companyName,
      email,
      phone,
      address_1,
      address_2,
      address_city,
      address_state,
      address_zip,
      address_country,
      send_receipt: true,
      ...(isCardPayment
        ? {
            method: "card",
            month: expiration.format("MM"),
            year: expiration.format("YYYY"),
          }
        : {
            method: "bank",
            bank_name,
            bank_type,
            bank_holder_type,
            bank_account,
            bank_routing,
            person_name: firstname + " " + lastname,
          }),
      validate: false,
      meta: {
        reference: "invoice-reference-num",
        memo: teamName,
      },
    };

    fattJs.current
      .pay(extraDetails)
      .then(async (payResult) => {
        const {
          payment_method_id,
          customer: { id: customerId },
        } = payResult;

        const { result, msg, extra } = financeDetailId
          ? await handleBilling({
              finance_detail_id: financeDetailId,
              status: 1,
              charged: pricing,
              response: payResult == "" || payResult.id == undefined ? "" : payResult.id,
            })
          : await handleBillingSuccess({
              team_id: teamId,
              packageType: packageType,
              charged: pricing,
              response: payResult == "" || payResult.id == undefined ? "" : payResult.id,
            });

        const scheduledInvoiceResult = await handleCreateScheduledInvoice({
          paymentMethodId: payment_method_id,
          customerId,
          freq: packageType === "annually" ? "YEARLY" : "MONTHLY",
          url: "https://omni.fattmerchant.com/#/bill/",
          total: pricing,
        });

        await handleTokenizeSuccess({
          response: scheduledInvoiceResult == "" || scheduledInvoiceResult.id == undefined ? "" : scheduledInvoiceResult.id,
        });

        setIsLoading(false);

        postMessage(extra || {});
        if (result) {
          return history.push(`/result/success/${teamName}/${pricing}`);
        } else {
          message.error(msg);
          return history.push(`/result/error/${pricing}`, {
            errorMsg: msg,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.status == 0) {
          message.error("Something went wrong.");
          return history.push(`/result/error/${pricing}`, {
            errorMsg: "Something went wrong.",
          });
        }
        if (err.message) {
          message.error(err.message);
        } else {
          if (err.status == "ATTEMPTED") {
            message.error(err.payment_attempt_message);
            return history.push(`/result/error/${pricing}`, {
              errorMsg: err.payment_attempt_message,
            });
          }
        }

       

        const errKeys = Object.keys(err);

        if (errKeys[0] === "errors") {
          handlePostError(err.errors[0]);

          return history.push(`/result/error/${pricing}`, {
            errorMsg: err.errors[0],
          });
        }

        errKeys.forEach((key) => {
          if (key === "person_name") {
            form.setFields([
              {
                name: "firstname",
                errors: ["The first name field is required."],
              },
            ]);
            form.setFields([
              {
                name: "lastname",
                errors: ["The last name field is required."],
              },
            ]);
            return;
          }
          form.setFields([
            {
              name: key,
              errors: [err[key]],
            },
          ]);
        });

        return setIsLoading(false);
      });
  };

  const showCardForm = () =>
    fattJs.current
      .showCardForm()
      .then((handler) => {
        //NOTE: testing
        // handler.setTestPan("4111111111111111");
        // handler.setTestCvv("123");
      })
      .catch((err) => {
        console.log("error init form " + err);
      });

  useEffect(() => {
    // fattmerchantdemo
    const FattJs = window.FattJs;
    fattJs.current = new FattJs(process.env.REACT_APP_FM_KEY, {
      number: {
        id: "fattjs-number",
        placeholder: "0000 0000 0000 0000",
        style:
          "width: 100%; height:100%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%; box-sizing: border-box;",
      },
      cvv: {
        id: "fattjs-cvv",
        placeholder: "000",
        style:
          "width: 100%; height:100%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%; box-sizing: border-box;",
      },
    });

    showCardForm();
  }, []);

  return (
    <>
      <div className="billing__form">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          form={form}
          onFinish={onPay}
          layout="vertical"
        >
          <div className="billing__form--title">Billing Details</div>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First name"
                name="firstname"
                rules={[
                  { required: true, message: "Please input your firstname!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Last name"
                name="lastname"
                rules={[
                  { required: true, message: "Please input your lastname!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Company name" name="companyName">
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Email address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                label="Billing address"
                name="address_1"
                rules={[
                  {
                    required: true,
                    message: "Please input your billing address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Address line 2" name="address_2">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Billing postal/Zip code"
                name="address_zip"
                rules={[
                  {
                    required: true,
                    message: "Please input your Billing postal/Zip code!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="City"
                name="address_city"
                rules={[{ required: true, message: "Please input your city!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="address_country"
                rules={[
                  { required: true, message: "Please input your country!" },
                ]}
                initialValue={country}
              >
                <Select
                  onChange={(value) => {
                    form.resetFields(["address_state"]);
                    return setCountry(value);
                  }}
                >
                  <Select.Option value="USA">United States</Select.Option>
                  <Select.Option value="CAN">Canada</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="State"
                name="address_state"
                rules={[
                  { required: true, message: "Please input your state!" },
                ]}
              >
                <Select>
                  {(country === "USA" ? USA_STATES : CAN_STATES).map(
                    ({ name, abbreviation }) => (
                      <Select.Option value={abbreviation}>{name}</Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="billing__form--title">Payment method</div>

          <Radio.Group
            className="billing__form--select-method"
            name="radiogroup"
            defaultValue={isCardPayment}
            onChange={({ target: { value } }) => {
              showCardForm();
              return setIsCardPayment(value);
            }}
          >
            <Radio value={true}>
              Card <CreditCardOutlined />
            </Radio>
            <Radio value={false}>
              Bank <BankOutlined />
            </Radio>
          </Radio.Group>

          {isCardPayment ? <CardPayment /> : <BankPayment />}

          <Form.Item>
            <Button
              className="billing__btn"
              icon={<LockFilled />}
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Pay ${pricing}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

BillingForm.propTypes = {};

export default BillingForm;
